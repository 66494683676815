@use 'src/styles/6-abstracts/variables' as var;
@use 'src/styles/2-components/modal';


.commentsModal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 40px;
    width: 500px;
    gap: 20px;
    color: var.$font-color-secondary;
    background-color: var.$background-color-secondary;
    border-radius: var.$border-radius-large;
    border-style: solid;
    border-width: 1px;
    border-color: var.$border-color-primary;
    font-family: var.$font-family-primary;

    .header{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: var.$font-family-primary-ultra-bold;
        font-size: var.$font-size-l;
        text-align: center;
        gap: 10px;

        .subTitle{
            font-family: var.$font-family-primary;
            font-size: var.$font-size-s;
            color: rgb(117, 117, 117);
        }
    }

    .oldComment {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .listCommentsWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-height: 175px;
        overflow-y: scroll;
        width: 420px;
        background-color: rgb(44, 113, 211, 10%);
        padding: 5px 10px;
        border-radius: 8px;

        .commentWrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            width: 100%;
            // word-wrap: 'break-word';
            padding-top: 20px; padding-bottom: 10px;

            .commentContent{ line-height: 120%;}

            .commentFooter{
                display: flex;
                flex-direction: row;
                gap: 10px;
                font-size: var.$font-size-s;
                opacity:50%;
            }
        }
    }
    .newComment {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
    }
    .footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-m;
        color: var.$font-color-third;
        gap: 20px;
        margin-top: 10px;
    }
}

.commentsModalSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 60px 40px 40px 40px;
    width: 500px;
    background-color: var.$background-color-secondary;
    border-radius: var.$border-radius-large;
    border-style: solid;
    border-width: 1px;
    border-color: var.$border-color-secondary;
    font-family: var.$font-family-primary-bold;
    line-height: 20px;
    color: var.$color-success;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: var.$font-size-l;
        gap: 10px;
    }
}
.commentsModalError {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 60px 40px 40px 40px;
    width: 500px;
    background-color: var.$background-color-secondary;
    border-radius: var.$border-radius-large;
    border-style: solid;
    border-width: 1px;
    border-color: var.$border-color-secondary;
    font-family: var.$font-family-primary-bold;
    line-height: 20px;
    color: var.$color-error;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: var.$font-size-2xl;
        gap: 10px;
    }
}