@use 'src/styles/6-abstracts/variables' as var;

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 480px;

  .cardModal {
    width: clamp(300px, 80%, 640px);
    max-width: 80%;
    max-height: 80%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background-color: var.$background-color-fifth;
    border: 1px solid var.$border-color-primary;
    border-radius: var.$border-radius-large;
    // color: var.$font-color-secondary;

    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: var.$font-family-primary-bold;
      font-size: var.$font-size-xl;
      text-align: center;

      .close_icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          background-color: var.$background-color-blue;
          color: white;
        }
      }
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      font-family: var.$font-family-primary;
      font-size: var.$font-size-page-title;
      text-align: center;

      #p {
        margin-bottom: 10px;
        font-size: 20px;
      }
    }

    .footer {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 20px;
      font-family: var.$font-family-primary;
      font-size: var.$font-size-m;
      text-align: center;

      .closeButton {
        padding: 10px 20px;
        background-color: hsl(0, 0%, 17%);
        border: none;
        border-radius: 10px;
        outline: none;
        color: var.$font-color-secondary;

        &:hover {
          background-color: hsl(0, 0%, 25%);
        }
      }

      .prepareButton {
        padding: 10px 20px;
        background-color: #ff8383;
        border: none;
        border-radius: 10px;
        outline: none;
        color: var.$font-color-primary;

        &:disabled {
          padding: 10px 20px;
          background-color: hsl(0, 100%, 95%);
          border-radius: 10px;
          color: var.$font-color-third;
        }

        &:hover {
          background-color: #ff0000;
          color: var.$font-color-secondary;
        }
      }

      .successButton {
        padding: 10px 20px;
        background-color: #15803d;
        border: none;
        border-radius: 10px;
        outline: none;
        color: var.$font-color-secondary;

        &:disabled {
          padding: 10px 20px;
          background-color: #86efac;
          border-radius: 10px;
          color: var.$font-color-third;
        }

        &:hover {
          background-color: #16a34a;
          color: var.$font-color-secondary;
        }
      }

      .normalButton {
        padding: 12.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: var.$background-color-sixth;
        border: 1px solid var.$font-color-secondary;
        border-radius: 10px;
        font-size: var.$font-size-l;
        color: var.$font-color-secondary;
        cursor: pointer;

        &:hover {
          background: var.$background-color-fourth;
        }
      }
    }
  }
}

.itemDetail {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;

  .itemKey {
    justify-self: flex-start;
    font-weight: bold;
  }

  .itemValue {
    justify-self: flex-start;
    text-align: left;
  }
}

.errorMsg {
  width: 100%;
  padding: 10px;
  font-family: var.$font-family-primary-bold;
  // font-size: 1.25rem;
  font-size: 12px;
  text-align: center;
  color: red;
}
