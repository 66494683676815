@use 'src/styles/4-pages/preflight' as preflight;
@use 'src/styles/6-abstracts/variables' as var;


.putDrone {
    display: flex;
}

.putDroneWrapper {
    background-color: var.$background-color-fifth;
    width: 400px;
    border-radius: 4px;
}