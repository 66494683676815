@use 'src/styles/6-abstracts/variables' as var;

.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.alertIcon {
  // background-color: red;
  border-radius: 50%;
  width: 25px; height: 25px;
}

.flightButton {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(var.$background-color-blue, 0.8);
    // border: 3px solid var.$background-color-blue;
    border-radius: 50%;

    &:hover {
        background-color: var.$background-color-blue;
        box-shadow: 0px 0px 10px rgba(0, 162, 255, 0.8);
        cursor: pointer;
    }
}

.flightButtonLoading {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(var.$background-color-secondary, 0.8);
    border-radius: 50%;

    &:hover {
        background-color: var.$background-color-blue;
        box-shadow: 0px 0px 10px rgba(0, 162, 255, 0.8);
        cursor: pointer;
    }
}

.downloadButton{
    display: flex; align-items: center; justify-content: center;
    width: 30px; height: 30px;
    border-radius: 50%;
    margin-right: -5px;

    &:hover{
      background-color: rgba(var.$background-color-blue, 0.1);
      color: var.$color-blue;
      cursor: pointer;
    }
  }
  .disabledDownloadButton{
    display: flex; align-items: center; justify-content: center;
    width: 30px; height: 30px;
    opacity: 50%;
    pointer-events: none;
    margin-right: -5px;
  }

.disable {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var.$background-color-primary;
    // border: 1px solid rgb(95, 95, 95);
    border-radius: 50%;
    // cursor: not-allowed;
}

// Define CSS variables for mui DataGrid design
$textColor: var.$font-color-primary;
$textColor2: orange;
$textColor3: gray;
$textColor4: rgb(255, 0, 0);


$headerColor: var.$font-color-third;
$headerFontFamily: var.$font-family-primary;
$headerFontSize: var.$font-size-s;

$tabBgColor: var.$background-color-primary;
$tabBorderRadius: var.$border-radius-large;
$tabBorder: 1px solid var.$border-color-primary;

$rowFontFamily: var.$font-family-primary-bold;
$rowBgColor: rgb(var.$background-color-blue, 5%);
$rowBorderRadius: var.$border-radius-medium;
$rowColor: var.$background-color-blue;

$panelBorderColor: var.$border-color-primary;

$overlayFontSize: var.$font-size-m;
$overlayColor: var.$background-color-fifth;

:export {
  textColor: $textColor;
  textColor2: $textColor2;
  textColor3: $textColor3;
  textColor4: $textColor4;

  headerColor: $headerColor;
  headerFontFamily: $headerFontFamily;
  headerFontSize: $headerFontSize;

  tabBgColor: $tabBgColor;
  tabBorderRadius: $tabBorderRadius;
  tabBorder: $tabBorder;

  rowFontFamily: $rowFontFamily;
  rowBgColor: $rowBgColor;
  rowBorderRadius: $rowBorderRadius;
  rowColor: $rowColor;

  panelBorderColor: $panelBorderColor;

  overlayFontSize: $overlayFontSize;
  overlayColor: $overlayColor;
}