@use '../6-abstracts/variables' as var;

.body {
  max-width: 2000px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 12px;
  z-index: 1;
  overflow: hidden;

  .functionBar {
    width: 100%;
    display: flex;
    padding-right: 4px;
    justify-content: flex-end;
    gap: 12px;
  }

  .addProductCardWrapper {
    // display: grid;
    // transition: grid-template-rows 0.5s;

    // > div {
    //   overflow: hidden;
    //   transition: opacity 0.5s ease-out
    // }

    // &.open {
    //   grid-template-rows: 1fr;

    //   > div {
    //     opacity: 1;
    //   }
    // }

    // &.closed {
    //   > div {
    //     padding-top: 0;
    //     padding-bottom: 0;
    //     opacity: 0;
    //   }

    //   grid-template-rows: 0fr;
    // }
  }

  .productTable {
    width: 100%;
  }

  .functionBar, .productTable {
    transition: filter 0.5s ease-out
  }

  .disabled {
    pointer-events: none;
    filter: brightness(92%);
  }
}
