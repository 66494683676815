@use '../6-abstracts/variables' as var;

.button_circle {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var.$background-color-fourth;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
  border-radius: 50%;
  font-family: var.$font-family-primary-ultra-bold;
  cursor: pointer;

  &:hover {
    background-color: var.$background-color-blue;
    color: white;
  }
}

.button_square {
  width: max-content;
  height: max-content;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: var.$border-radius-small;
  font-family: var.$font-family-primary-ultra-bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var.$background-color-secondary;
    color: white;
  }
}

.button_basic {
  position: relative;
  width: max-content;
  padding: 8px 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
  border-radius: var.$border-radius-medium;
  font-family: var.$font-family-primary-bold;
  font-size: var.$font-size-m;

  &:hover {
    background-color: var.$font-color-primary;
    font-family: var.$font-family-primary-ultra-bold;
    color: white;
    cursor: pointer;
  }
}

.button_icon {
  width: max-content;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var.$background-color-secondary;
  border-radius: var.$border-radius-medium;
  // box-shadow: var.$shadow-20-primary;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
  font-family: var.$font-family-primary-bold;
  font-size: var.$font-size-m;
  color: var.$font-color-secondary;
  user-select: none;

  .rotate90 { transform: rotate(90deg) }
  .rotate180 { transform: rotate(180deg) }
  .rotate270 { transform: rotate(270deg) }

  &:hover {
    background-color: var.$background-color-blue;
    font-family: var.$font-family-primary-bold;
    color: white;
    cursor: pointer;
  }

}
.no_border {  
  box-shadow: none;
  padding: 0px;
  &:hover {
    box-shadow: none;
  }
}
.unset{
  background-color: unset;
  &:hover {
    background-color: unset;
    color: red;
  }
}
.unset_blue{
  background-color: unset;
  &:hover {
    background-color: unset;
    color: var.$background-color-blue;
  }
}
.red { 
  background-color: rgb(255, 196, 196); 
  color: red;
  &:hover {
    background-color: red;
    color: white;
  }
}
.green { 
  background-color: rgb(182, 255, 182);
  color: green;
  &:hover {
    background-color: green;
    color: white;
  }
}
.mobileMenu {
  background-color: rgb(var.$background-color-third, 20%);
  color: var.$font-color-secondary;
  &:hover {
    background-color: rgb(var.$background-color-third, 5%);
    color: var.$font-color-secondary;
  }
}

@mixin button_card_circle(
  $bg-color-primary,
  $bg-color-secondary,
  $color-primary,
  $color-secondary
) {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: $color-primary;
  background-color: $bg-color-primary;
  height: 25px;
  width: 25px;
  font-family: var.$font-family-primary-ultra-bold;

  border: solid;
  border-width: 1px;
  border-color: var.$border-color-third;

  &:hover {
    background-color: $bg-color-secondary;
    // color: $color-secondary;
    cursor: pointer;
  }
}
.button_card_delete {
  @include button_card_circle(
    var.$background-color-primary,
    var.$background-color-blue,
    var.$font-color-primary,
    var.$font-color-secondary
  );
}
.button_card_edit {
  @include button_card_circle(
    var.$background-color-primary,
    var.$background-color-blue,
    var.$font-color-primary,
    var.$font-color-secondary
  );
}
.button_card_confirm {
  @include button_card_circle(
    var.$color-completed,
    rgba(var.$color-completed, 0.9),
    var.$font-color-secondary,
    var.$font-color-secondary
  );
}

.disabled_button {
  pointer-events: none;
  filter: opacity(50%);
  // box-shadow: none;
}