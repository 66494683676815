@use '../6-abstracts/variables' as var;

.auth {
  // width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var.$background-color-fifth;

  .background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content_wrapper {
    z-index: 1;
    width: 400px;
    // height: 450px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: var.$border-radius-large;
    box-shadow: 0 4px 4px rgb(0 0 0 / 5%);

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo_container {
        padding: 10px 0;
      }

      .title {
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-xl;
      }

      .subTitle {
        padding-bottom: 10px;
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-m;
      }
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .textField {
        width: 70%;
        font-family: var.$font-family-primary-bold;
      }

      .text_error {
        width: 70%;
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-s;
        color: red;
      }
    }

    .footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logInButton {
        width: 70%;
        height: 40px;
        margin-top: 20px;
        background-color: var.$background-color-secondary;
        font-family: var.$font-family-primary-bold;
        font-size: var.$font-size-m;
      }
    }

    .progress_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
