@use '../6-abstracts/variables' as var;

.body {
  width: 100%;
  max-width: 2000px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  #bloc1 {
    width: 100%;
    z-index: 2;
  }

  #bloc2 {
    width: 100%;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media (min-width: 776px) and (max-width: 1200px) {
      grid-template-columns: 1fr;
      // grid-template-rows: 1fr 1fr;
    }
    @media screen and (max-width: 775px) {
      grid-template-columns: 1fr 1fr;
      // grid-template-rows: 1fr 1fr;
    }
  }
}
