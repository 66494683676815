:root {
  font-size: 14px;
  // cursor: url(../../media/icons/arrow-pointer-solid.svg), default;
}

a {
  text-decoration: none;
  color: black;
}

// Cursor
$cursor-secondary: default;

// Breakpoints



// Font size
// $font-size-xs: 10px;
// $font-size-s: 12px;
// $font-size-m: 14px;
// $font-size-l: 20px;
// $font-size-xl: 22px;
$font-size-xs: 0.715rem;
$font-size-s: 0.85rem;
$font-size-m: 1rem;
$font-size-l: 1.15rem;
$font-size-xl: 1.43rem;
$font-size-2xl: 1.57rem;
$font-size-3xl: 2.05rem;

$font-size-page-title: $font-size-l;
$font-size-page-subtitle: $font-size-xl;

// Font family
$font-family-primary: 'MuseoSansCyrl-300';
$font-family-primary-bold: 'MuseoSansCyrl-500';
$font-family-primary-ultra-bold: 'MuseoSansCyrl-700';

// Font colors
$font-color-primary: rgb(255, 255, 255);
$font-color-secondary: rgb(25, 25, 25);
$font-color-third: rgb(162, 162, 162);
$font-color-fourth: rgb(117, 117, 117);

// Background colors
$background-color-primary: rgb(31, 31, 31);// rgb(25, 25, 25);
$background-color-secondary: rgb(255, 255, 255);
$background-color-third: rgb(47, 47, 47);// black; // rgb(33, 33, 33);
$background-color-fourth: rgb(243, 244, 245);
$background-color-fifth: rgb(212, 212, 212);
$background-color-sixth: rgb(0, 0, 0);
$background-color-neutral: rgb(125, 125, 125);
$background-color-blue: rgb(44, 113, 211);
$background-color-lightBlue:rgb(204, 224, 251);
$background-color-neutral-light: rgb(219, 219, 219);

// Other colors
$color-pending: #d442c8; // #ba68c8;
$color-scheduled: #74afd2; // #ba68c8;
$color-approved: #3d5a80; //#03a9f4;
$color-ready: #ffb703; // #ffe100;
$color-ready-to-load: #d4b242;
$color-loading: #31e721;
$color-loaded: #067910;
$color-in-flight: #fb8500; // #ff9800;
$color-ready-to-unload: #d4b242;
$color-cancelled: #ef5350;
$color-completed: #4caf50;
$color-aborted: #af574c;
$color-danger: rgb(190,0,0);
$color-blue: rgb(6, 95, 217);
$color-orange:rgb(217, 105, 6);
$color-success: #4caf50;
$color-error: rgb(190,0,0);

// Other colors - hubs & ground handling actions status
$color-action-ready: rgb(6, 95, 217);
$color-action-ongoing: #ffb700;
$color-status-green: #4caf50;
$color-status-orange: #fb8500;
$color-status-red: rgb(190,0,0);


// Shadows
$shadow-20-primary: 0px 0px 20px rgba(
  $color: #000000,
  $alpha: 0.2,
);
$shadow-20-secondary: 0px 0px 20px rgba(
  $color: #000000,
  $alpha: 0.3,
);
$shadow-50-primary: 0px 0px 50px rgba(
  $color: #000000,
  $alpha: 0.2,
);
$shadow-50-secondary: 0px 0px 50px rgba(
  $color: #000000,
  $alpha: 0.3,
);
$shadow-75-primary: 0px 0px 75px rgba(
  $color: #000000,
  $alpha: 0.15,
);
$shadow-75-secondary: 0px 0px 75px rgba(
  $color: #000000,
  $alpha: 0.3,
);

// Border radius
$border-radius-large: 16px;
$border-radius-medium: 12px;
$border-radius-small: 6px;

// Border color
$border-color-primary: rgb(17, 17, 17);
$border-color-secondary: rgb(20, 20, 20);
$border-color-third: rgb(50, 50, 50);



// Global breakpoints
