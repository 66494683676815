@use '../6-abstracts/variables' as var;

.card_section {
  height: 100%;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var.$background-color-primary;
  border: 1px solid var.$border-color-primary;
  border-radius: var.$border-radius-large;
  font-family: var.$font-family-primary;
  color: var.$font-color-primary;

  .header_wrapper {
    height: 50px;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var.$font-family-primary-ultra-bold;
    font-size: var.$font-size-l;
    user-select: none;
  }
}

.card_table {
  // To use with for datagrid table
  height: 100%;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: var.$background-color-primary;
  border: 1px solid var.$border-color-primary;
  border-radius: var.$border-radius-large;
  font-family: var.$font-family-primary;

  .toolbar_wrapper {
    height: 40px;
    margin-bottom: 10px;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var.$font-family-primary-ultra-bold;
    font-size: var.$font-size-l;
    user-select: none;
  }
}

.card_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border-radius: var.$border-radius-medium;
  box-shadow: 0 4px 4px rgb(0 0 0 / 50%);
  user-select: none;
}

:root {
  --delivery-status: 0%;
}

// Pre flight cards
.card_preflight {
  height: 70px;
  width: max-content;
  padding: 20px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  background-color: var.$background-color-primary;
  border: 1px solid var.$border-color-primary;
  border-radius: var.$border-radius-large;
  font-family: var.$font-family-primary;
  font-size: var.$font-size-l;
  color: var.$font-color-primary;
}

// Delivery cards
@mixin card_del(
  $bg_color_primary,
  $bg_color_secondary,
  $bg_color_third,
  $text_color_primary
) {
  min-width: 280px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: $bg_color_primary;
  border-radius: var.$border-radius-large;
  font-family: var.$font-family-primary-bold;
  color: $text_color_primary;

  .header_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .header_reference {
      width: calc(10ch + 30px);
      padding: 10px 15px;
      background-color: $bg_color_secondary;
      border-radius: var.$border-radius-medium;
      font-size: var.$font-size-s;
      text-align: center;
    }

    .header_eta {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .field_name {
        font-size: var.$font-size-xs;
      }

      .field_content {
        font-size: var.$font-size-l;
      }
    }
  }

  .body_wrapper {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 15px;
    font-family: var.$font-family-primary;

    .body_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      // width: 50%;

      .field_name {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10px;
      }

      .field_content {
        max-width: 100%;
        display: block;
        overflow-wrap: break-word;
        font-size: var.$font-size-s;
        font-family: var.$font-family-primary-bold;
        
        > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .footer_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    .progressbar_wrapper {
      width: 100%;
      height: 10px;
      background-color: $bg_color_secondary;
      border-radius: var.$border-radius-large;
      border-style: solid;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.01);

      .progressbar_content {
        background-color: $bg_color_third;
        height: 100%;
        width: max-content;
        border-radius: var.$border-radius-large;
      }
    }

    .progressbar_status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15%;
      font-size: var.$font-size-s;
    }
  }
}

.card_del {
  @include card_del(
    var.$background-color-primary,
    //rgb(250, 250, 250),
    var.$background-color-fifth,
    //rgb(212, 212, 212), //var.$background-color-third,
    var.$background-color-primary,
    var.$font-color-primary
  );

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);

  &:hover {
    @include card_del(
      var.$background-color-third,
      var.$background-color-fifth,
      var.$background-color-primary,
      var.$font-color-primary
    );
  }
}

// Goods card
.card_goods {
  position: relative;
  user-select: none;

  &:hover {
    .card_menu {
      opacity: 1;
      transform: translateY(0%);
      transition: all 0.2s ease-in-out;
      pointer-events: all;
    }
    
    .card_wrapper {
      background-color: var.$background-color-third;
      transition: all 0.2s ease-in-out;
    }
  }

  .card_menu {
    // display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    opacity: 0;
    pointer-events: none;
    transform: translateY(200%);
    transition: all 0.1s ease-in-out;
  }

  .first_card_wrapper {
    width: 300px;
    // min-width: 300px;
    // height: 180px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: var.$background-color-primary;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var.$border-radius-large;
    box-shadow: 0 4px 4px rgb(0 0 0 / 50%);
    font-family: var.$font-family-primary-bold;
    color: var.$font-color-primary;

    .header_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        flex: 1;
      }

      .button {
        flex: 0 0 auto;
      }
    }

    .body_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 10px;

      .body_item {
        max-width: 320px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-s;

        & > :first-child {
          width: 80px;
          margin-left: 20px;
        }
      }
    }
  }

  .card_wrapper {
    width: 300px;
    // min-width: 300px;
    // height: 180px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: var.$background-color-primary;
    border: 1px solid var.$border-color-secondary;
    border-radius: var.$border-radius-large;
    box-shadow: 0 4px 4px rgb(0 0 0 / 50%);
    font-family: var.$font-family-primary-bold;
    color: var.$font-color-primary;

    .header_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        flex: 1;
      }

      .button {
        flex: 0 0 auto;
      }
    }

    .body_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 10px;

      .body_item {
        max-width: 320px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        font-family: var.$font-family-primary;
        font-size: var.$font-size-s;

        & > :first-child {
          width: 80px;
          margin-left: 20px;
        }
      }
    }
  }
}

.card_goods_add {
  width: 90px;
  // height: 180px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: rgba(var.$background-color-primary, 0.9);
  border: 1px solid var.$border-color-secondary;
  border-radius: var.$border-radius-large;
  //box-shadow:  0 4px 4px rgb(0 0 0 / 5%);
  font-family: var.$font-family-primary-bold;
  color: var.$font-color-primary;

  .icon_wrapper {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var.$background-color-third;
    border-radius: 50px;
  }

  &:hover {
    box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
    cursor: pointer;
    background-color: var.$background-color-blue;
  }
}

// OrderHub Picker card
.card_hubSel {
  position: relative;
  max-width: 330px;
  min-width: 170px;
  height: 200px;
  user-select: none;
  flex-grow: 1;

  .card_edit {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    margin-left: 10px;
    z-index: 4;
  }

  .card_wrapper {
    margin-top: 10px;
    width: 100%;
    height: 100%;

    .card_menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: var.$background-color-third;
      border-radius: var.$border-radius-large;

      .menu_title {
        color: var.$font-color-primary;
        user-select: none;
      }
    }

    .card_status {
      position: absolute;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      z-index: 2;

      .current_hub {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 30px;
        width: max-content;
        // opacity: 80%;
        padding: 0 20px;
        border-radius: 0 var.$border-radius-medium 5px var.$border-radius-large;
        background-color: var.$background-color-primary;
        color: var.$font-color-primary;
        border: 1px solid var.$background-color-third;
      }
    }

    .card_bg {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      // padding: 10px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid var.$background-color-third;
      border-radius: var.$border-radius-large;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
      overflow: clip;

      .hub_image {
        // width: 100%;
        // min-width: 200px;
        height: 120%;
        // max-width:300px;
        // max-height: 100%;
        // border-radius: var.$border-radius-large;
        // border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
      }
    }
  }
}

// OrderTime Picker card
.card_orderTime {
  width: 100%;
  // max-width: 450px;
  min-width: 200px;
  height: 200px;
  margin-top: 10px;
  padding: 10px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: 1px solid var.$border-color-secondary;
  border-radius: var.$border-radius-large;
  box-shadow: 0 4px 4px rgb(0 0 0 / 50%);
  user-select: none;

  .card_header {
    font-family: var.$font-family-primary-bold;
  }

  .card_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .card_option1 {
      width: 100px;
      height: 53.108px;
      display: flex;
      align-items: center;
      justify-items: center;
      border: 1px solid rgb(0 0 0 / 25%);
      border-radius: var.$border-radius-small;
      box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
      text-align: center;

      &:hover {
        cursor: pointer;
        border-color: black;
      }
    }

    .card_option2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
      border-radius: var.$border-radius-small;
    }

    .card_option1Selected {
      width: 100px;
      height: 53.108px;
      display: flex;
      align-items: center;
      justify-items: center;
      background-color: var.$background-color-third;
      border: 1px solid var.$background-color-third;
      border-radius: var.$border-radius-small;
      box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
      text-align: center;
      color: var.$font-color-primary;

      &:hover {
        cursor: default;
      }
    }
  }

  @media (min-width: 850px) {
    max-width: 450px;

    .card_wrapper {
      flex-direction: column;
    }
  }

  @media (min-width: 1025px) {
    .card_wrapper {
      flex-direction: row;
    }
  }
}
